import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

// Loader component
const Loader = ({ size, color, template }) => {
  const getBaseColor = (template) => {
    const colors = {
      btc: "#3c6e95",
      radcliffe: "#143557",
      talbies: "#B09981",
      charlessinclair: "#c7b354",
      denhan: "#b82142",
      daniels: "#090B0C",
      magicbrick: "#ffcc00",
      propertyhubltd: "#ffcc00",
      lundiniom: "#ffc800",
      eliteintl: "#F80229",
      foster: "#403634",
    };
    return colors[template] || "#ac6dfd";
  };

  const getLighterColor = (color) => {
    const bigint = parseInt(color.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, 0.6)`;
  };

  const baseColor = getBaseColor(template);
  const lighterColor = getLighterColor(baseColor);

  return (
    <div style={{ width: '100%', height: size === 'small' ? '40px' : size === 'medium' ? '50px' : '180px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress size={size === 'small' ? 20 : size === 'medium' ? 40 : 100} style={{ color: lighterColor }} />
    </div>
  );
};

export default Loader;
